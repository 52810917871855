<template>
     <div class="invite">
          <el-dialog v-model="dialogVisible" :lock-scroll="false" width="560px">
               <div class="invite_con">
                    <div class="title">受邀投标方</div>
                    <div class="close_tab">
                         <span class="el-icon-close" @click="dialogVisible = false"></span>
                    </div>
                    <div class="table">
                         <el-table :data="inviteList" style="width: 100%" table-layout="fixed" height="400">
                              <el-table-column prop="companyName" label="企业名称" width="249" align="center" />
                              <el-table-column prop="contactPhone" label="联系方式" width="249" align="center" />
                         </el-table>
                    </div>
               </div>
               <div class="box11 flex_row_center_center">

                    <div class="box12g flex-col" @click="dialogVisible = false">
                         <span class="txt17">确定</span>
                    </div>
               </div>
          </el-dialog>
     </div>
</template>

<script>
     import { ref, reactive, getCurrentInstance, computed, onMounted, watch } from 'vue'
     export default {
          props: {
               visi: Boolean
          },
          setup(props) {
               const dialogVisible = ref(false)
               const inviteList = ref([])
               const { proxy } = getCurrentInstance()

               const openList = (bidId) => {
                    getList(bidId)
                    dialogVisible.value = true
               }


               const getList = (bidId) => {
                    proxy.$post('v3/business/front/bid/invitee', { bidId }).then(res => {
                         if (res.state == 200) {
                              inviteList.value = res.data
                         }
                    })
               }

               return {
                    dialogVisible,
                    openList,
                    inviteList
               }
          }
     }
</script>


<style lang="scss">
     .el-dialog .el-dialog__header {
          display: none;
     }

     .el-dialog__body {
          padding: 0;
     }

     .invite_con {
          padding: 30px;
          position: relative;

          .close_tab {
               position: absolute;
               top: 20px;
               right: 20px;

               span {
                    font-size: 20px;
               }

               cursor: pointer;
          }

          .title {
               font-size: 22px;
               font-family: Microsoft YaHei;
               font-weight: bold;
               color: #000000;
               text-align: center;
          }

          .search_wrap {
               margin-top: 46px;

               .info1 {
                    white-space: nowrap;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
               }

               .wrap3 {
                    width: 257px;
                    height: 34px;
                    background: #FFFFFF;
                    border: 1px solid #BFBFBF;
                    border-radius: 5px;
                    padding: 0 10px;
               }

               .word3 {
                    white-space: nowrap;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    margin: 0px 0 0 22px;
               }



               .wrap7 {
                    background-color: rgba(40, 95, 222, 1);
                    border-radius: 4px;
                    height: 32px;
                    width: 68px;
                    margin: 0px 0 0 31px;
                    cursor: pointer;

                    .info3 {
                         width: 28px;
                         height: 13px;

                         color: rgba(255, 255, 255, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                         display: block;
                         margin: 9px 0 0 19px;
                    }
               }

               .wrap8 {
                    border-radius: 4px;
                    height: 32px;
                    border: 1px solid rgba(40, 95, 222, 1);
                    width: 68px;
                    margin: 0px 0 0 20px;
                    cursor: pointer;

                    .info4 {
                         width: 29px;
                         height: 13px;

                         color: rgba(40, 95, 222, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                         display: block;
                         margin: 9px 0 0 19px;
                    }
               }
          }

          .table {
               margin-top: 20px;
               border: 1px solid #E8E8E8;
               border-bottom: none;

               .has-gutter tr th {
                    background: #F2F2F2;
               }
          }


     }

     .box11 {
          box-shadow: 0px 2px 10px 0px rgba(131, 131, 131, 0.3);
          background-color: rgba(255, 255, 255, 1);
          height: 79px;
          /* margin-top: 38px; */

          .box12g {
               width: 120px;
               height: 40px;
               background: #285FDE;
               border-radius: 3px;
               cursor: pointer;
               text-align: center;
               line-height: 40px;

               .txt17 {
                    color: rgba(255, 255, 255, 1);
                    font-size: 16px;
                    white-space: nowrap;
                    display: block;
               }
          }


          .box12f {
               width: 120px;
               height: 40px;
               background: #FFFFFF;
               border: 1px solid #BFBFBF;
               border-radius: 3px;
               cursor: pointer;
               text-align: center;
               line-height: 40px;
               margin-right: 50px;

               .txt17 {

                    color: #333333;
                    font-size: 16px;
                    white-space: nowrap;
                    display: block;
               }
          }
     }
</style>